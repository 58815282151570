import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticQuery, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from 'gatsby-image'
class AboutPage extends React.Component {
  
    render(){
      
      let team = [
        {
          name: "Nick Hall",
          bio: [
            "Nick has 14 years engineering experience. He is a highly skilled fabricator and draftsman with polished skills in 3D and 2D modelling software.",
            "After he left school and went straight into the engineering industry after completing a pre-trade course at the Otago Polytechnic.  After completing his apprenticeship and serving his time at Farra Engineering he moved to Australia to broaden his skills and knowledge in stainless steel fabrication. He then moved back to Otago to complete a Mechanical Engineering diploma. This Qualification lead to a Project Management role. In his personal time Nick is always busy with his own engineering projects. He enjoys building and creating things which are outside of the norm. He can sometimes be found fly fishing on a back country river."
          ],
          image: this.props.data.nick.childImageSharp.fluid
        },
        {
          name: "Cory McEachen",
          bio: [
            "Cory McEachen started his sheet metal fabrication career in a small workshop in Mosgiel. A high percentage of his apprenticeship was spent at the local PPCS (Silver Fern Farms) meat processing plant where he was introduced to working within the food industry and the associated food grade requirements.",
            "He then joined his fathers building company to learn a new trade in carpentry and worked on everything from new builds, new kitchens, new bathrooms and renovation work. After 6 years in the building industry local work started slowing down, prompting Cory to look at work back in the engineering sector. It was here he was involved in one of Dunedin’s major redevelopments with Speight’s brewery; fabricating their brewing vessels and being involved with on site pipe work."
          ],
          image: this.props.data.cory.childImageSharp.fluid
        }
      ]
      return(
        <Layout containerClass="about">
            <SEO title="Allcor Fabrication - About Us" />
            <section className="hero">
              <div className="uk-cover-container uk-height-viewport height-fixed">
                <Img fluid={this.props.data.header.childImageSharp.fluid} className="uk-height-viewport"/>
                <div class="uk-width-1-1 uk-height-viewport custom-overlay">
                  <div class="uk-overlay uk-position-bottom"> 
                    <h1 className="uk-text-left">About</h1>                                
                  </div>
                </div>
                <AnchorLink className="more" href="#main">
                    <FontAwesomeIcon icon="chevron-down" />
                </AnchorLink>
              </div>
              
            </section>

            <section className="section intro pad-200" id="main">
              <div className="uk-container uk-container-center uk-height-1-1">
                <div className="uk-flex uk-flex-wrap uk-flex-middle uk-flex-center uk-height-1-1">
                    <div className="uk-width-1-1 uk-width-3-4@l">
                    <h1>ALLCOR FABRICATION LTD was started in 2015 by Nick Hall and Cory McEachen.</h1>
                      <h2>The pair have a wide range of experience in the engineering and building industries. 
                        They believe that it is important to complete every project on time, 
                        with a high standard of workmanship, while sticking to the budget.
                      </h2>
                    </div>
                  </div>
                </div>
            </section>

            <section className="section team-members white">
              <div className="uk-container uk-container-center">
                {team.map((member, i) => (
                  <div key={i} className="uk-flex uk-flex-wrap uk-flex-middle uk-flex-row-reverse uk-flex-space-between">
                  <div className="uk-width-1-1 uk-width-1-4@l">
                    <Img fluid={member.image}/>
                    </div>
                    <div className="uk-width-1-1 uk-width-2-3@l">
                      <h2 className="">{member.name}</h2>
                      {member.bio.map((para, in_i) => (
                        <p key={in_i}>{para}</p>
                      ))}
                      
                    </div>
                    
                </div>  
                ))}
              </div>
            </section>                       
        </Layout>
      )
    }
}

export default AboutPage



export const query = graphql`
  query aboutQuery {
    header: file(relativePath: {  eq: "new/PH01.jpg" } ) {
        childImageSharp {
            fluid(maxWidth: 1500,) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
    }
    nick:file(relativePath: { eq: "about/nick.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
    }
    cory:file(relativePath: { eq: "about/cory.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
    }
  }
`;